<template>
    <div class="chatbot-container" :class="{ 'minimized': isMinimized }">
      <div class="chatbot-header" @click="toggleMinimize">
        <h4 class="chatbot-title">ENSD BOT</h4>
          
      </div>
      <div class="chatbot-body" v-if="!isMinimized">
        
        <p>Hello! May I help you? You can talk to me in every language.</p>
        <li v-if="isWaitingForResponse">🤖Waiting for response🤖</li>
        <ul class="message-list">
          
          <li v-for="(message, index) in messages" :key="index" :class="{'user-message': message.isUser, 'bot-message': !message.isUser}">
            {{ message.text }}
          </li>
        </ul>
        <input type="text" v-model="newMessage" @keyup.enter="sendMessage" class="message-input" :disabled="isWaitingForResponse">
  
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import axios from 'axios';
  
  export default {
    name: 'GChatBot',
    setup() {
      const isMinimized = ref(true); // 
      const newMessage = ref(''); // 
      const messages = ref([]); // 
      const threadId = ref(null);
      const isWaitingForResponse = ref(false);
  
    const sendMessage = async () => {
      if (newMessage.value.trim() !== '') {
        messages.value.push({ text: newMessage.value, isUser: true });
        
        isWaitingForResponse.value = true

        try {
          const response = await axios.post(
            'https://d3499nhhgw212j.cloudfront.net/api/chat', 
            // 'http://127.0.0.1:5000/api/chat',
            { 
              message: newMessage.value,
              thread_id: threadId.value }
          );
          threadId.value = response.data.thread_id;

          messages.value.push({ text: response.data.reply, isUser: false });
        } catch (error) {
          messages.value.push({ text: 'Failed to get response from the API', isUser: false });
        }
        isWaitingForResponse.value = false
        newMessage.value = ''; // Limpiar después de enviar
      }
    };
  
      const toggleMinimize = () => {
        isMinimized.value = !isMinimized.value;
      };
  
      return {
        isMinimized,
        newMessage,
        messages,
        sendMessage,
        toggleMinimize,
        threadId,
        isWaitingForResponse
      };
    }
  };
  </script>
  
<style scoped>
  .chatbot-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .chatbot-header {
    background-color: rgb(7, 172, 181);
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center; /* Centra el título */
    align-items: center; /* Alinea verticalmente */
  }
  
  .chatbot-title {
    margin: 0; /* Elimina márgenes externos */
    padding: 0; /* Elimina rellenos */
    /* Ajustes adicionales para el título */
  }

  .minimized {
    height: 40px;
    width: 200px;
  }

  .minimized .chatbot-header {
    justify-content: flex-start; /* Alinea el título a la izquierda cuando está minimizado */
  }

  .minimized .chatbot-title {
    display: inline; /* Asegura que el título se muestre en línea */
    font-size: 1em; /* Ajusta el tamaño del texto para mejor visibilidad */
    /* Otros estilos para el título cuando está minimizado */
  }
  
  .chatbot-body {
    padding: 10px;
    height: calc(100% - 40px); /* Altura del header */
    overflow-y: auto; /* Permite el desplazamiento si el contenido es muy largo */
    color:black;
    display: flex;
    flex-direction: column;
  }

  .message-list {
  flex-grow: 1;
  overflow-y: auto; /* Hace la lista de mensajes desplazable */
  padding: 10px;
  }

  .message-input {
    border: none;
    border-top: 1px solid #f20e0e;
    padding-bottom: 30px;
  }

  .user-message {
    text-align: right; /* Alinea tus mensajes a la derecha */
    background-color: rgb(22, 173, 22); /* Fondo verde claro para tus mensajes */
    color: black; /* Letra negra */
    padding: 5px;
    margin: 5px 0;
    border-radius: 10px;
  }

  .bot-message {
    text-align: left; /* Alinea los mensajes del chatbot a la izquierda */
    /* Estilos para los mensajes del chatbot */
    padding: 5px;
    margin: 5px 0;
    border-radius: 10px;
  }
  
  </style>