<template>
    <div class="projects-container">
      <div class="project" v-for="(project, index) in projects" :key="index">
        <div class="project-number">{{ index + 1 }}</div>
        <div class="project-info">
          <h3 class="project-title">{{ project.title }}</h3>
          <div class="project-status">{{ project.status }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProjectPage',
    data() {
      return {
        projects: [
          { title: 'Internationalizing Magallanes for Engineering 2030 - Chile & Switzerland', status: 'In progress' },
          { title: 'AI Trainer for Bilingual Engineering Courses', status: 'In progress' },
          { title: 'Logistics for Cono Sur Production - North Europe Final Destination', status: 'Planning phase' },
          { title: 'Human capital system for early learner generations in Latin America', status: 'Planning phase' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
.projects-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the container */
  justify-content: center;
  height: 100vh; /* Full viewport height */
  padding-left: 5vw; /* Space from the left side of the viewport */
  background-color: black; /* Your choice of background */
  color: white; /* Text color */
}

.project {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 60px; /* Space between projects */
}

.project-number {
  font-size: 2em; /* Size of the project number */
  margin-right: 20px; /* Space after the project number */
}

.project-info {
  border-left: 2px solid white; /* Left border as a separator */
  padding-left: 20px; /* Space after the separator */
}

.project-title {
  font-size: 1.5em; /* Size of the project title */
  margin: 0; /* Remove default margins */
}

.project-status {
  font-size: 1em; /* Size of the project status */
  margin-top: 5px; /* Space between title and status */
  padding: 2px 10px; /* Padding inside the status box */
  border: 1px solid white; /* Border around the status box */
  display: inline-block; /* Display as inline-block for proper padding */
}


  @media (max-width: 768px) {
    .project-title {
      font-size: 1em;
    }
  
    .project-status {
      padding: 3px 10px;
    }
  
    .project-number {
      width: 40px;
      height: 40px;
      font-size: 1em;
    }
  }
  </style>