<template>
    <div class="container">
        <h1 class="title"> Chief Executive Officer</h1>
        <div class="content">
            <div class="text-class">
                <h1>Valdivia Gonzalo</h1>
                <p style="padding-top: 5vw;">Gonzalo Valdivia has seamlessly blended academia and the private sector throughout his professional career. </p>
                <p>Over the span of 2018-2023, Valdivia has actively engaged in collaborative initiatives with universities in Chile, United States, and Switzerland, fostering academic exchange. Simultaneously, his involvement with burgeoning engineering-related enterprises has been marked by a focus on niche market consulting. These endeavors have propelled him into the realm of cutting-edge technologies, offering transnational services to European countries’ firms from 2019 to 2023, and more recently, extending his reach to South Korea in 2023. 
                </p>
                <p>In the private sector, Valdivia progressed from logistics to leading strategies for Latin America and Europe. Recent years saw him align corporate pursuits with independent project management, fostering connections with global industry leaders, mainly in the automobile, shipping, and energy sector.
                </p>
                <p>His international experience has given rise to a globalized communication structure, contributing to the establishment of a cooperative network centered around engineering. Notably, this network aligns harmoniously with Chile's mission for global prominence and leadership in energy exports.
                </p>
                <p>Valdivia's narrative extended beyond the corporate realm, as evidenced by his affiliation with the Energy Conversion and Combustion Group. His commitment to continuous learning was underscored by his participation in courses and diplomas related to energy management and heat transfer. Recognition in 2012 by the Chilean Mining Council further attested to his contributions to innovation projects in the mining and education sectors.
                </p>
            </div>
            <div class="image-class">
                <img src="@/assets/team/gonzalo_1.jpg" alt="LinkedIn" />
            </div>
        </div>       
    </div>
</template>

<script>

export default {
    name: "ValdiviaPage",
    setup () {
        return {}
    }
}

</script>

<style scoped>
.content{
    display: flex;
    flex-wrap: nowrap;
    margin: 4em;
    gap: 4em;
    align-content: center;
    justify-content: center;
}

.title {
    color: white;
    margin-top: 3em;
    margin-left: 1em;
}

.text-class {
    flex: 1;
    font-size: 1vw;
}

.text-class h1 {
    padding-top: 5vw;
}

.image-class {
    flex: 1;
    align-content: center;
}

.image-class img{
    width: 41vw;
    min-width: 200px; /* Prevents the image from becoming too small */
    border: 10px solid transparent; /* Define un borde transparente con el ancho deseado */
    scale: 0.75;
}
</style>