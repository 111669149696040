<template>
    <div class="text-catch-section">

      <h2>
        Our mission is to bring 
        <a target="_blank" class="highlighted-link"> industry and academia together </a> <br/>
        to contribute to the  <a target="_blank" class="highlighted-link"> sustainability goals achievement </a>  <br/>
        and cooperation agreements of <a target="_blank" class="highlighted-link"> our countries</a>   <br/>
        throughout <a target="_blank" class="highlighted-link"> human capital development</a>    <br/>
      </h2>
      </div>
      
</template>

<script>

</script>
  
  
  <style scoped>
  .text-catch-section {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 250px; /* Espacio adicional en la parte superior */
    margin-bottom: 400px; /* Espacio adicional en la parte inferior */
    font-size: 1.7em; /* Aumenta el tamaño de la fuente */

  
    /* Más estilos según sea necesario */
  }

  .highlighted-link {
    color: yellow; /* Replace with the color you want */
    text-decoration: none; /* Optional: removes the underline */
  }

   
  /* Estilos para h2 y p si es necesario */
  </style>