<template>
    <div class="container">
        <h1 class="title"> Chief Network Officer </h1>
        <div class="content">
            <div class="text-class">
                <h1>Rivera, Jose</h1>
                <p>Jose is a member of the Fire Research Lab of the Mechanical Engineering Department in the Energy Science and Technology area at the University of California at Berkeley in California, United States. He also participates as a peer reviewer for the Fire Safety Journal and Defense Technology.  
                </p>
                <p>Rivera’s current work and projects participation:
                </p>
                <ul>
                    <li style="margin-bottom: 1.5em;">NASA SoFIE-MIST: “Solid Fuel Ignition and Extinction” (SoFIE) Project on International Space Station (ISS). (i) Studying ignition and flammability of solid spacecraft materials. (ii) Determining safer selection of cabin materials and validating NASA materials flammability selection using 1-g test protocols for low-gravity fires through the “Material Ignition and Suppression Test” (MIST) project. </li>
                    <li style="margin-bottom: 1.5em;">NASA Saffire: “Spacecraft Fire Safety” mission. On Earth experiments and analysis complementing the microgravity experiments on board of Cygnus spacecraft for Space Exploration Atmospheres (SEA) conditions.</li>
                    <li style="margin-bottom: 1.5em;">NASA FLARE/JAXA: “Fundamental Research on International Standard of Fire Safety in Space” project of the Japan Aerospace Exploration Agency (JAXA) under NASA collaboration. Application of AI algorithms to model wire burning behavior in spacecraft environments. Supporting the experiments of the International Space Station (ISS) – KIBO unit, Japanese Experiment Module (JEM). </li>
                </ul>
            </div>
            <div class="image-class">
                <img src="@/assets/team/ignacio_1.jpg" alt="LinkedIn" />
            </div>
        </div>

        <div class="content" style="padding-top:20vw">
            <div class="image-class">
                <img src="@/assets/team/ignacio_2.jpg" alt="LinkedIn" />
            </div>
            <div class="text-class">
                <h1>Rivera, Jose</h1>
                <p>The research he has frequently led includes:</p>
                <ul>
                    <li style="margin-bottom: 1.5em;">Studying the fundamental principles governing the fire behavior and solid ignition, including combustion, heat transfer, and fluid dynamics within fire environments.</li>
                    <li style="margin-bottom: 1.5em;">Theoretical modeling to understand the mechanisms initiating combustion in solid materials, including thermal ignition, chemical reactions, and external factors like embers and radiative heating. </li>
                    <li style="margin-bottom: 1.5em;">Assessing the flammability of materials commonly used in aerospace construction, including interior components, insulation, and coatings, to develop fire-resistant alternatives. </li>
                    <li style="margin-bottom: 1.5em;">Development of strategies to use machine learning tools to better understand and model fire phenomena such as solid ignition and flame spread processes in confined environments for fire safety standards in both high-altitude and spacecraft applications.</li>
                </ul>
            </div>

        </div>

        
    </div>
</template>

<script>

export default {
    name: "NaxoPage",
    setup () {
        return {}
    }
}

</script>

<style scoped>
.content{
    display: flex;
    flex-wrap: nowrap;
    margin: 4em;
    gap: 4em;
}

.title {
    color: white;
    margin-top: 3em;
    margin-left: 1em;
}

.text-class {
    flex: 1;
    font-size: 1vw;
}
.image-class {
    flex: 1;
    align-content: center;
}

.image-class img{
    width: 41vw;
    min-width: 200px; /* Prevents the image from becoming too small */
    border: 10px solid transparent; /* Define un borde transparente con el ancho deseado */
}
</style>