import { createRouter, createWebHistory } from "vue-router";
import Home from '../views/HomePage.vue';
import ContactPage from '../views/ContactPage.vue'
import TeamPage from '../views/TeamPage.vue'
import ProjectPage from '../views/ProjectPage.vue'
import AboutPage from '../views/AboutPage.vue'
import NaxoPage from '../components/memberComponent/RiveraView.vue'
import ValdiviaPage from '../components/memberComponent/ValdiviaView.vue'
import MarchantPage from '../components/memberComponent/MarchantView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage
    },
    {
        path: '/team',
        name: 'team',
        component: TeamPage,
        children: [
            {
                path: 'rivera',
                name: 'jose',
                component: NaxoPage
            },
            {
                path: 'valdivia',
                name: 'gonzalo',
                component: ValdiviaPage
            },
            {
                path: 'marchant',
                name: 'tomas',
                component: MarchantPage
            },
        ]
    },
    {
        path: '/projects',
        name: 'projects',
        component: ProjectPage
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router;