<template>
    <div class="container">
        <h1 class="title"> Chief Technology Officer</h1>
        <div class="content">
            <div class="text-class">
               <h1>Marchant Tomás</h1>
                <p style="padding-top: 5vw;">Tomás has dedicated a significant portion of his career to technological entrepreneurship, excelling in the development and management of innovative products within startup environments. Throughout his career, Tomás has not only coded but also managed customer relations and led teams as a product manager.
                </p>
                <p>His technology solutions have been embraced by some of the largest retail startups in Chile, proving the relevance and impact of his work across platforms like iOS, Android, and web. Tomás has also built a substantial career as a data scientist, focusing on recommendation systems and, more recently, deep learning language models (LLMs).

                </p>
                <p>His approach to problem-solving is characterized by seeking elegant and novel solutions, enhancing his professional journey and contributing significantly to technological advancements in his fields of interest. Tomás's diverse expertise positions him as a leader and innovator, continually pushing the boundaries of technology application and knowledge.
                </p>
            </div>
            <div class="image-class">
                <img src="@/assets/team/marchant_1.jpeg" alt="LinkedIn" />
            </div>
        </div>       
    </div>
</template>

<script>

export default {
    name: "MarchantPage",
    setup () {
        return {}
    }
}

</script>

<style scoped>
.content{
    display: flex;
    flex-wrap: nowrap;
    margin: 4em;
    gap: 4em;
    align-content: center;
    justify-content: center;
}

.title {
    color: white;
    margin-top: 3em;
    margin-left: 1em;
}

.text-class {
    flex: 1;
    font-size: 1vw;
}

.text-class h1 {
    padding-top: 5vw;
}

.image-class {
    flex: 1;
    align-content: center;
}

.image-class img{
    width: 41vw;
    min-width: 200px; /* Prevents the image from becoming too small */
    border: 10px solid transparent; /* Define un borde transparente con el ancho deseado */
    scale: 0.75;
}
</style>