<template>
  <nav-bar/>
  <router-view></router-view>
  <GChatBot/>
  
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import GChatBot from '@/components/ChatBot.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    GChatBot
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  background-color: #000; /* Fondo negro */
  color: #fff; /* Texto blanco */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

