<template>
  <section class="hero">
    <div class="hero-text">
      <h1>Connecting international talent for the <br> development of advanced human capital</h1>
      <button @click="moveAbout" class="learn-more-btn"> Learn about ENSDHub </button>
    </div>
  </section>
</template>
  
  <script>
  import {useRouter} from 'vue-router';

  export default {
    name: 'HeroSection',
    setup () {
      const router = useRouter()

      const moveAbout = () => {
        console.log("Button clicked"); // This should appear in the console on click
        router.push("/about");
      };

      return {moveAbout}
    }
  }
  </script>
  
  <style scoped>
.hero {
    padding-top: 80px;
    background-image: url('./../../assets/hero_section_background.jpg'); /* Ruta de la imagen */
    background-size: cover; 
    background-position: center center;
    height: 720px;
    position: relative; /* Necesario para la posición del degradado */
    /* ... otros estilos ... */
  }


.hero::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%; /* Ajusta la altura del degradado según necesites */
    background: linear-gradient(to top, rgba(0, 0, 0, 9), transparent);
  }

  .hero-text {
    position: absolute; /* Cambia a posicionamiento absoluto */
    bottom: 15%; /* Espacio desde la parte inferior */
    left: 8%; /* Espacio desde la izquierda */
    text-align: left; /* Alineación del texto a la izquierda */
    max-width: 60%; /* Máximo ancho del texto para controlar el flujo en dos líneas */
    padding: 0; /* Remueve el padding si no es necesario */
    align-items: flex-start; /* Aligns items to the start (left) */
  }
  
  .hero-text h1 {
    font-size: 2.2em;
    line-height: 1; /* Ajusta el espacio entre líneas */
    word-wrap: break-word; /* Asegúrate de que el texto se ajuste correctamente */
  }


.learn-more-btn {
  padding: 10px 20px; 
  margin-top: 20px; 
  border: 2px  solid white; /* poner solid antes de white pal boton */
  background-color: transparent; /* Transparent background */
  color: white; /* aqui el texto */
  font-weight: bold; 
  font-size: 1em; 
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s; 
}
.learn-more-btn:hover {
  background-color: white; /* Correct background color on hover */
  color: black; /* Text color change on hover */
}


@media (max-width: 768px) {
  .hero {
    flex-direction: column; /* Stack image and text on smaller screens */
    background-size: auto;
  }

  .hero-text {
    align-items: center; /* Keep text centered on smaller screens */
  }

  .learn-more-btn {
    padding: 8px 15px; /* Smaller padding on smaller screens */
    font-size: 0.9em; /* Smaller font size on smaller screens */
  }
}
</style>

