<template>
    <div id="home">
        <HeroSection/>
        <RippleEffect/>
        <OurProposal/>
        <VennDiagVue/>
        <newCatchPhraseVue/>
    </div>
</template>

<script>
import HeroSection from '../components/oldHomeComponents/HeroSection.vue'
import RippleEffect from '@/components/homeComponents/RippleEffect.vue';
import VennDiagVue from '@/components/homeComponents/VennDiag.vue';
import newCatchPhraseVue from '@/components/homeComponents/newCatchPhrase.vue';
import OurProposal from '@/components/homeComponents/OurProposal.vue';

export default {
    name: 'HomePage',
    components: {
        HeroSection,
        RippleEffect,
        VennDiagVue,
        newCatchPhraseVue,
        OurProposal,
        // CatchPhraseVue,
        // ScrollingGif,
        // KpiSection,
        // CatchPhraseTwoVue,
        // PorscheBack,
        // WindMillBackVue,
        // CloudBack
    }
}
</script>