<template>
  <div id="about">
      <MissionPage/>
      <BlueCarBackground/>
  </div>
</template>

<script>

import MissionPage from '@/components/aboutComponents/MissionComponent.vue'
import BlueCarBackground from '@/components/aboutComponents/BlueCarBackground.vue'


export default {
  name: 'AboutPage',
  components: {
    MissionPage,
    BlueCarBackground

  }
}
</script>