<template>
  <section class="team-section">
    <div class="team-container" v-if="!isChildRouteActive">
      <div 
        class="team-member" 
        v-for="member in teamMembers" 
        :key="member.name"
        @mouseover="member.hover = true"
        @mouseleave="member.hover = false"
        @click="goToMemberPage(member.routeName)"
        :class="{ 'clickable': true }"
      >
        <img :src="member.imageSrc" :alt="`Photo of ${member.name}`" class="team-photo"/>
        <div class="name-and-linkedin">
          <a :href="member.linkedinUrl" target="_blank" class="linkedin-icon">
            <img src="@/assets/team/linkdn.png" alt="LinkedIn" />
          </a>
          <h3 class="team-name">{{ member.name }}</h3>
        </div>
        <p class="team-title">{{ member.title }}</p>
        <p class="team-bio" :class="{ 'hovered': member.hover }" v-html="member.bio" > </p>
      </div>
    </div>
    <router-view></router-view>
  </section>
  <div class="network-visual" @mouseover="handleContainerMouseOver">
    <div v-for="n in 300" :key="n" class="network-square"></div>
    <p class="network-message">We have an International Network with more than 20 Scientific and Industrial People - mainly in the United States, Switzerland, and Germany.</p>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TeamPage',
  setup() {
    const hover = ref(false);
    const bioGonzalo = 'Engineering project lead in energy management studies and sustainable technology evaluations. Business strategy experience in the private sector. Collaborator with universities in the Americas <br> and Europe'
    const bioNaxo = 'Research assistant at University of California, Berkeley. Currently working in NASA SoFIE-MIST and    NASA Saffire projects on the International Space Station. Peer reviewer for Fire Safety Journal and Defense <br> Technology.'
    const bioTom = 'Experienced leader in both product and commercial teams, specializing in the development of web and mobile applications with artificial intelligence solutions. Skilled in managing high-profile projects and driving <br> team on a dynamic market environment'

    const allSquaresGone = ref(false);

    const handleContainerMouseOver = () => {
      if (!allSquaresGone.value) {
        allSquaresGone.value = true; // Prevent further trigger
        document.querySelectorAll('.network-square').forEach(sq => {
          sq.style.transition = 'opacity 0.5s ease';
          sq.style.opacity = 0;
        });
      }
    };
    const teamMembers = reactive([
        { 
        name: 'Gonzalo Valdivia', title: 'CEO',
        routeName: 'valdivia',
        imageSrc: require('@/assets/team/ENSDGON.png'),
        bio: bioGonzalo,
        hover: false,
        linkedinUrl: 'https://www.linkedin.com/in/g-valdivia/'
        },
        { 
        name: 'Jose Rivera', title: 'CNO',
        routeName: 'rivera',
        imageSrc: require('@/assets/team/ENSDNAXO.png'),
        bio: bioNaxo,
        hover: false,
        linkedinUrl: 'https://www.linkedin.com/in/jose-rivera-305523281/'
        },
        { 
        name: 'Tomas Marchant', title: 'CTO',
        routeName: 'marchant',
        imageSrc: require('@/assets/team/ENSDTOM.png'),
        bio: bioTom,
        hover: false,
        linkedinUrl: 'https://www.linkedin.com/in/tomas-marchant/'
        },
      ]);

      const route = useRoute();
      const router = useRouter();

      const goToMemberPage = (routeName) => {
        router.push(`/team/${routeName}`);
      };



      const isChildRouteActive = computed(() => {
        return route.path.includes('/team/');
      });


    return { 
      teamMembers, 
      hover, 
      isChildRouteActive, 
      goToMemberPage,
      handleContainerMouseOver,
      allSquaresGone
 };
  },
};


</script>

<style scoped>
.team-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding-top: 20px;
}

.team-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  gap: 200px; 
  max-width: 1400px; 
  margin: auto; 
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px; 
  cursor: pointer; 
}

.team-photo {
  filter: grayscale(100%); /* Start with a black-and-white image */
  transition: filter 0.3s ease-in-out; /* Smooth transition for filter effect */
  width: 260px; 
  height: 300px; 
  object-fit: cover; 
  margin-bottom: 10px; 
}

.team-member:hover .team-photo {
  filter: grayscale(0%); /* Full color on hover */
}

.team-name,
.team-title,
.team-bio {
  text-align: center; 
}
.team-name {
  font-size: 1.2em;
  margin-bottom: 5px; 
}

.team-title {
  font-size: 1em;
  color: #efeeee; 
}
.name-and-linkedin {
  display: flex; 
  align-items: center; 
  gap: 10px; 
}
.linkedin-icon {
  order: -1;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: 10px; 
}

.linkedin-icon img {
  width: 24px; 
  height: 24px; 
}

.team-bio {
  text-align: center;
  font-size: 1 em;
  position: relative;
  color: transparent;
  background: linear-gradient(rgb(255, 255, 255), transparent);
  -webkit-background-clip: text;
  background-clip: text;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-top: 10px; 
  cursor: default;

  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  height: 4.5em; 
  margin: 0 10px; 
  line-height: 1.5em; 
  max-width: 420px; 
  margin-left: -50px; 
  margin-right: -50px; 
}

.hovered {
  color: white; 
  background: none; 
}

.team-bio:hover {
  background: linear-gradient(black, black);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-line-clamp: none;
}
.network-visual {
  position: relative;
  width: 100%;
  height: 150px; /* Adjusted for better visibility */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures squares do not overflow the designated area */
}

.network-square {
  width: 20px;
  height: 20px;
  background-color: white;
  margin: 1px;
}

.network-message {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
  color: white; /* Make the text white */
  font-size: 20px;
  opacity: 0; /* Hidden initially */
  transition: opacity 1s ease-in-out, font-size 0.3s ease; /* Transition for visibility and font size */
}

.network-visual:hover .network-message {
  opacity: 1; /* Show text after squares disappear */
  font-size: 24px; /* Enlarged on hover */
}

@media (max-width: 1100px) {
  .team-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    padding-top: 100px;
  }

}
</style>
