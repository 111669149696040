<template>
  <div class="back-ground-proposal" >
    <div class="proposal-text">
      <div class="header">
        <img src="@/assets/proposal-icon.png" alt="Ripple Effect Icon" class="ripple-icon" />
        <h2 class="title">OUR <br/> PROPOSAL</h2>
      </div>
      <div class="content">
        Strategically link key players in the green hydrogen industry with professionals through a robust human capital program emphasizing technical expertise and effective communication.


      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: 'OurProposalPage',

};
</script>


<style scoped>
.back-ground-proposal {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./../../assets/back-our-proposal.png');
  background-size: cover; /* Cover the entire area of the container */
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  z-index: 5; /* Ensure it's above other content */
}

.back-ground-proposal::before,
.back-ground-proposal::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6; /* Asegúrate de que la sombra esté por encima del fondo pero debajo del texto */
}

.back-ground-proposal::before {
  top: 0;
  height: 10%; /* Ajusta el tamaño de la sombra superior */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
}

.back-ground-proposal::after {
  bottom: 0;
  height: 15%; /* Ajusta el tamaño de la sombra inferior */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}

.proposal-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3em;
  color: white;
  padding-top: 12%;
  z-index: 8
}

.header {
  display: flex;
  align-items: center;
  width: 30%;
  border-bottom: 2px solid white; /* White line */
}

.ripple-icon {
  width: 70px; /* Adjust as needed */
}

.title {
  margin-left: 0.3em;
  padding-bottom: 0.5em;
}

.content {
  padding-top: 25px;
  display: flex;
  width: 30%;
  font-size: 1.5em;
  
}

</style>

