<template>
  <nav class="navbar">
    <router-link to="/" class="logo-link">
      <img 
        src="@/assets/ENSDHubLogo.svg" 
        alt="Logo de ENSDhub" 
        class="logo"
      >
      <!-- <img 
        src="@/assets/real_logo.svg" 
        v-if="route.name === 'team'"
        alt="Logo del Equipo" 
        class="logo"
      > -->
    </router-link>
    <ul class="nav-menu">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/projects">Projects</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/team">Our Team</router-link></li>
      <!-- <li><router-link to="/">News</router-link></li> -->
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
  </nav>
</template>
  
<script>
// import { useRoute } from 'vue-router';

// export default {
//   name: 'NavBar',
//   setup() {
//     const route = useRoute();

//     return { route };
//   },
// };
</script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    position: fixed; /* Posición absoluta */
    top: 0; /* En la parte superior */
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent); 
    color: #fff;
    z-index: 10; /* Asegúrate de que esté por encima de otras secciones */
  }

  .navbar.team-page {

  }
  
  .nav-menu {
    list-style: none;
    display: flex;
  }


  
  .nav-menu li {
    padding: 0 10px;
    transition: transform 0.2s ease, font-weight 0.2s ease;
  }
  
  .nav-menu li:hover {
    transform: scale(1.1); /* Scale up li element */
    font-weight: bold; /* Make text bolder */
  }

  .nav-menu li a {
    color: #fff;
    text-decoration: none;
  }

  .nav-menu.team-page li a{
    color: black;
  }

  .nav-menu li a.router-link-active {
    font-weight: bold; /* Estilo para el link activo */
  }
  
  .logo {
    height: 50px; /* Ajusta esto según el tamaño deseado */
    width: auto; 
    padding-left: 4%;
  }
  </style>