<template>
    <div class="contact-page">
      <div class="contact-form">
        <input type="email" v-model="email" placeholder="Your Email" class="email-input" required />
        <textarea v-model="message" placeholder="Your Message" class="message-input"></textarea>
        <button @click="sendContactRequest" type="button" class="submit-button">Send</button>
      </div>
      <div v-if="loading" class="loading-indicator">
        Loading...
      </div>
      <div v-if="responseMessage" class="response-popup">
        <button class="close-button" @click="closePopup">✖</button>
        {{ responseMessage }}
      </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import { ref } from 'vue';

export default {
  name: 'ContactPage',
  setup() {
    const email = ref('');
    const message = ref('');
    const responseMessage = ref('');
    const loading = ref(false);
    
    const closePopup = () => {
      responseMessage.value = '';
    };

    const sendContactRequest = async () => {
      loading.value = true;
      try {
        const response = await axios.post(
      'https://d3499nhhgw212j.cloudfront.net/api/contact',
      {
        email: email.value,
        message: message.value
      },
      {
        timeout: 30000,
      }
    );
        responseMessage.value = response.data.reply;
      } catch (error) {
        console.error('Error sending contact request:', error);
        responseMessage.value = 'Failed to send message.';
      }
      loading.value = false;
    };

    return { email, message, sendContactRequest, responseMessage, loading, closePopup };
  },
};
</script>

<style scoped>
.contact-page {
/* Estilos para la página de contacto */
background-image: url('../assets/contact-background.jpg');
background-size: cover;
background-position: center center;
background-repeat: repeat;
z-index: 5; /* Ensure it's above other content */
width: 100vw; /* Full viewport width */
height: 100vh; /* Full viewport height */
}


.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 22em;
  gap: 20px; /* Spacing between elements */
}

.email-input,
.message-input {
  width: 60%; /* Adjust width as needed */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75); /* Black with 50% transparency */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6); /* Shadow effect */
  border-radius: 5px; /* Slight rounding of corners */
  color: white; /* White text */
  font-size: 1.1em; /* Adjust font size as needed */
}

.email-input::placeholder,
.message-input::placeholder {
  color: white; /* White placeholder text */
}

.message-input {
  height: 150px; /* Adjust height as needed */
  resize: vertical; /* Allow only vertical resizing */
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: black; /* White button background */
  color: white; /* Black button text */
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
  background-color: white; /* Invert colors on hover */
  color: black;
}

.loading-indicator {
  /* Shared styles for popup and loading indicator */
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  z-index: 100;
  color: black; /* Ensures text is black */
}

.response-popup {
  /* Enhanced styles for popup */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  z-index: 100;
  color: black;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2em;
}



</style>