<template>
  <div class="back-ground-ripple" >
    <div class="ripple-effect-text">
      <div class="header">
        <img src="@/assets/ripple_effect_icon.png" alt="Ripple Effect Icon" class="ripple-icon" />
        <h2 class="title">RIPPLE EFFECT</h2>
      </div>
      <div class="content">
        <div class="points-list">
          <div class="point">
            <span class="number">1</span>
            <span class="text"> Climate change and evolving regulations drive a surge in demand for cleaner, high-energy-density fuels like green hydrogen.</span>
          </div>
          <div class="point">
            <span class="number">2</span>
            <span class="text">Demand for green products has spurred competition among nations with renewable production capacities and within the industrial transportation sector.</span>
          </div>
          <div class="point">
            <span class="number">3</span>
            <span class="text">Large-scale installed capacity is essential to lower production costs and achieve competitive pricing for hydrogen products.</span>
          </div>
          <div class="point">
            <span class="number">4</span>
            <span class="text">Need for technological advancements is driving the requirement for cultivating fresh, international, and adaptable human resource profiles.</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: 'RippleEffectPage',

};
</script>


<style scoped>
.back-ground-ripple {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./../../assets/back-ripple.png');
  background-size: cover; /* Cover the entire area of the container */
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  z-index: 5; /* Ensure it's above other content */
}

.back-ground-ripple::before,
.back-ground-ripple::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6; /* Asegúrate de que la sombra esté por encima del fondo pero debajo del texto */
}

.back-ground-ripple::before {
  top: 0;
  height: 10%; /* Ajusta el tamaño de la sombra superior */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), transparent);
}

.back-ground-ripple::after {
  bottom: 0;
  height: 15%; /* Ajusta el tamaño de la sombra inferior */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}

.ripple-effect-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3em;
  color: white;
  padding-top: 12%;
  z-index: 8
}

.header {
  display: flex;
  align-items: center;
  width: 30%;
  border-bottom: 2px solid white; /* White line */
}

.ripple-icon {
  width: 70px; /* Adjust as needed */
}

.title {
  margin-left: 0.3em;
  padding-bottom: 0.5em;
}

.content {
  display: flex;
  width: 100%;
}

.points-list {
  display: flex;
  flex-direction: column;
  max-width: 30%;
  margin-top: 2em;
}

.point {
  display: flex;
  margin-bottom: 1em;
}

.number {
  font-weight: bold;
  margin-right: 0.5em;
}

.text {
  max-width: 100%;
}

@media (max-width: 768px) {
  .points-list {
    max-width: 100%;
  }

  .point {
    flex-direction: column;
  }

  .number {
    margin-bottom: 0.5em;
  }
}

</style>

