<template>
    <div class="mission-container">
      <div class="mission-content">
        <h1 class="mission-title">Our mission</h1>
        <p class="mission-text">To empower the engineers of tomorrow with the technical skills they need to excel in an ever-evolving world</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MissionPage',
  };
  </script>
  
  <style scoped>
  .mission-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url('@/assets/rocket.png') no-repeat left bottom; /* Adjust the position */
    background-size: 70% auto; /* Adjust the size to be smaller */
    display: flex;
    align-items: center; /* This will vertically center the mission-content */
    justify-content: flex-start; /* Aligns content to the left */
    overflow: hidden; /* Ensure shadows don't expand past the container's bounds */

  }
  .mission-container::before,
.mission-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1; /* Below the content but above the background */
}

.mission-container::before {
  top: 0;
  height: 20%; /* Height of the shadow at the top */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
}

.mission-container::after {
  bottom: 0;
  height: 20%; /* Height of the shadow at the bottom */
  background: linear-gradient(to top, rgba(0, 0, 0, 3), transparent);
}

.mission-content {
  z-index: 2; /* Above the shadows */
  /* ... existing styles ... */
}

  
  
  .mission-content {
    position: absolute;
    bottom: 15%; /* Adjust this value to move the text higher or lower */
    left: 58%; /* Adjust this value as needed for left margin */
    text-align: left;
    color: white;
    max-width: 40%; /* Add a max-width to constrain the text block */
  }
  
  .mission-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem; /* Add space between title and text */
    text-align: center;
  }
  
  .mission-text {
    font-size: 1.5rem;
    text-align: center;
  }
  </style>
  