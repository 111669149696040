<template>
  <div class="blue-car-background" >
    <div class="text-catch-section">
      <h2> <b>We envision a future where bilingual engineers drive sustainability, innovation and connections in challenging areas – as green hydrogen applications and combustion research
      </b></h2>
  </div>
  </div>
</template>
<script>

export default {
  name: 'BlueCarBackground',

};
</script>


<style scoped>
.blue-car-background {
  position: relative;
  top: 175px;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./../../assets/car-background.png');
  background-size: cover; /* Cover the entire area of the container */
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  z-index: 5; /* Ensure it's above other content */
}

.blue-car-background::before,
.blue-car-background::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 6; /* Asegúrate de que la sombra esté por encima del fondo pero debajo del texto */
}

.blue-car-background::before {
  top: 0;
  height: 75%; /* Ajusta el tamaño de la sombra superior */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 5), transparent);
}

.blue-car-background::after {
  bottom: 0;
  height: 75%; /* Ajusta el tamaño de la sombra inferior */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}


.text-catch-section {
  display: flex;
  flex-direction: column; /* Los elementos se apilan verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  align-items: center; /* Centra el contenido horizontalmente */
  text-align: center; /* Alinea el texto al centro */
  position: absolute; /* Posicionamiento absoluto */
  top: 50%; /* Coloca el inicio del contenido en la mitad de la pantalla */
  left: 40%; /* Coloca el inicio del contenido en la mitad horizontalmente */
  transform: translate(-40%, -60%); /* Centra el contenido exactamente en el medio */
  font-size: 1em; /* Tamaño de la fuente */
  color: #FFF; /* Color del texto */
  z-index: 7; /* Asegúrate de que el texto esté por encima de las sombras */
}
</style>

