import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.use(VueGtag, {
  config: { id: 'G-C46XBD2C2Z' } // Your Measurement ID
}, router);

app.use(router);

app.mount('#app');